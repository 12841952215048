import { useState, useEffect } from 'react';
import api from '../api';

export default function GroupConfiguration() {
    const [data, setData] = useState([]);
    const request = {
        resource: 'api/groups',
    };
    useEffect(() => {
        const fetchGroup = async () => {
            try {
                const Grouplist = await api.grouplist.getList(request);
                setData(Grouplist);
            } catch (error) {
                console.log(error);
            }
        };
        fetchGroup();
    }, []);

    return { data };
}
