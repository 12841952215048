import { toastr } from 'react-redux-toastr';
import api from '../../api';
import { validate } from '../../helper/validateMasterConfiguration';
import { formatConfigurationData } from '../../helper/formatConfigurationData';
import { dateRange } from '../add-masterConfiguration/contants';
import { calculateDayOftheyear } from '../../helper/calculateDayoftheyear';

export const handleOnSubmit = async (
    data,
    id,
    checkType,
    setCustomErrors,
    navigate,
    attribute,
    dirtyFields,
    authState,
) => {
    try {
        console.log("submit data======>>>>>",data);
        if (data.date === 29 && new Date() % 4 !== 0)
            toastr.info('Info', 'This run day only applicable for leap years!');
        if (data?.frequency === 'Yearly' && data?.month && data?.date) {
            data.run_day = calculateDayOftheyear(
                new Date(
                    `${dateRange[data?.month]?.order}/${
                        data?.date
                    }/${new Date().getFullYear()}`,
                ),
            );
        }
        let errors = validate(data, checkType, attribute);
        if (Object.values(errors).length >= 1) {
            setCustomErrors(errors);
            return null;
        } else setCustomErrors({});
        console.log(data, 'before');
        let formatedData = formatConfigurationData(data, checkType);
        console.log("formatedData====>>>>",formatedData);
        const request = {
            resource: 'api/masterConfiguration',
            resourceId: id,
        };

        const res = await api.crud.update(request, {
            ...formatedData,
            check_type: checkType,
            dirtyFields: dirtyFields,
            authState,
        });
console.log("submit Page response=======>>>>>>",res);
        if (res.data.status === 200) {
            toastr.success('Success', 'Metrics updated Successfully');
            navigate('/');
        } else {
            toastr.error('Error', res.data.message);
        }
    } catch (error) {
        toastr.error('Error', error.message);
    }
};
