/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import api from '../../api';
import { Multiselect } from 'react-widgets';
import 'react-widgets/scss/styles.scss';
import { Controller, useForm, getValues } from 'react-hook-form';
import { Form, Row, Col, Button, Spinner, Container } from 'react-bootstrap';
import Dropdown from '../form-controls/Dropdown';
import { DISTRIBUTION, FRESHNESS, VOLUME } from '../../helper/variables';
import { useNavigate } from 'react-router-dom';
import Input from '../form-controls/Input';
import AceEditor from 'react-ace';
import useMasterConfiguration from './useEditMasterConfiguration';
import { useParams } from 'react-router-dom';
import ContainerLoader from '../loader/container-loader';
import { FaArrowLeft } from 'react-icons/fa';
import { stringTypes } from '../../utils/dataTypes';
import ConfirmationModal from '../confirmatinModal/confirmationModal';
import { handleOnSubmit } from './submit';
import { dateRange, days, months } from '../add-masterConfiguration/contants';
import { getDateBasedOnDayVal } from '../../helper/calculateDayoftheyear';
import GroupConfiguration from '../../hooks/group';
import { useSelector } from 'react-redux';
import CustomQueryResultTable from '../add-masterConfiguration/customQuertyResultTable';
export default function EditMasterConfiguration() {

    const { id } = useParams();

    const authState = useSelector((state) => state.authUser);
    const [list, setList] = useState({});
    const [modalShow, setModalShow] = useState(false);
    const [tempdata, setTempdata] = useState();
    const share_to = ['Group', 'Users', 'Everyone'];
    const [option, setOption] = useState([]);
    const [user, setUser] = useState([]);
    const [groupselected, setGroupselected] = useState([]);
    const [userselected, setuserselected] = useState([]);
    const [notify, setNotify] = useState([]);
    const [everyone, seteveryone] = useState(false);
    const [query, setQuery] = useState('');
    const [datasourceValue, setDataSourceValue] = useState("");
    const [isQueryValid, setIsQueryValid] = useState(false);
    const [dateColumsForCustomQuery, setDateColumsForCustomQuery] = useState([]);
    const [queryResult, setQueryResult] = useState(null);
    const [customQueryError, setCustomQueryError] = useState("");
    const [isButtonLoader, setIsButtonLoader] = useState(false);

    const request = {
        resource: 'api/users',
        limit: undefined,
    };
    const { data: groupData } = GroupConfiguration();

    const defaultValues = {
        table_name: '',
        dataSourceValue: '',
        column_name: '',
        check_type: '',
        group_by_col: '',
        date_field: '',
        frequency: '',
        query: '',
        metricValue: '',
        run_day: '',
        run_time: '00:00:00',
        latency: 0,
        agg_type: '',
        additional_threshold: 0,
        minimum_threshold: 0,
        month: '',
        date: '',
        notify_to: '',
        selected_group: '',
        selected_user: '',
        share_to: '',
        custom_query_availability: '',
    };


    // const {
    //     handleSubmit: handleSubmitForCustomquery,
    //     control: controlForCustomquery,
    //     formState: { isSubmitting: isSubmittingForCustomquery },
    //     setValue: setValueForCustomquery,
    //     getValues: getValuesForCustomquery,
    //     reset: resetForCustomquery,
    // } = useForm({
    //     reValidateMode: 'onChange',
    //     defaultValues,
    // });
    const {
        handleSubmit,
        control,
        formState: { errors, isSubmitting, dirtyFields },
        reset,
        setValue,
        getValues
    } = useForm({
        reValidateMode: 'onChange',
    });

    const navigate = useNavigate();

    const {
        sourceListData,
        datasourceListData,
        sourceListByTableName,
        dateColums,
        customErrors,
        setCustomErrors,
        isLoading,
        setIsLoading,
        setTableName,
        tableName,
        attribute,
        setLoadGroupBy,
        setAttribute,
        checkType,
        setCheckType,
        globalLoader,
        month,
        setMonth,
        frequency,
        setFrequency,
        loadMinThreshold,
        setLoadMinThreshold,
    } = useMasterConfiguration();

    const handleClearTableNameRelated = () => {
        setValue('column_name', '');
        setValue('group_by_col', '');
        setValue('date_field', '');
        setValue('additional_threshold', '');
    };

    const handleClearFrequencyRelated = () => {
        setValue('run_day', '');
        setValue('month', '');
        setMonth('');
        setValue('date', '');
    };
    const handleClear = () => {
        reset(defaultValues);
        setCustomErrors({});
        setLoadGroupBy(false);
    };

    const HandleQueryClear = () => {
        setValue('query', '');
        setQuery('');
        setIsQueryValid(false);
        setQueryResult('');
    };

    const fetchUserTable = async () => {
        try {
            setIsLoading(true);
            const userData = await api.crud.getList(request);
            setUser(userData.data.data.rows);
            setIsLoading(false);
        } catch (error) { }
    };
    useEffect(() => {
        fetchUserTable();
    }, []);
    useEffect(() => {
        if (!option.includes('Users')) {
            setuserselected([]);
            setValue('selected_user', []);
        }
        if (!option.includes('Group')) {
            setGroupselected([]);
            setValue('selected_group', []);
        }
        if (option.includes('Everyone')) {
            seteveryone(true);
        } else {
            seteveryone(false);
        }
    }, [option]);
    const getRundayVal = (val) => {
        if (frequency === 'Weekly') return val?.value;
        if (frequency === 'Monthly') return val;
    };


    const attributeData = () => {
        let data = [];
        if ([VOLUME].includes(checkType)) {
            sourceListByTableName?.forEach(({ column_name, data_type }) => {
                if (stringTypes.includes(data_type)) {
                    data.push({
                        data_type,
                        column_name: `${column_name} - count`,
                    });
                    data.push({
                        data_type,
                        column_name: `${column_name} - distinct`,
                    });
                    return;
                }
                data.push({
                    data_type,
                    column_name: `${column_name} - count`,
                });
                data.push({
                    data_type,
                    column_name: `${column_name} - sum`,
                });
            });
            return data;
        }
        return sourceListByTableName;
    };

    const modalSubmit = async (data) => {
        handleOnSubmit(
            data,

            id,
            checkType,
            setCustomErrors,
            navigate,
            attribute,
            dirtyFields,
            authState,
        );
    };

    const globalSubmit = async (data) => {
        console.log("global submit data====>>>>>>>", data);
        const temp = Object.keys(dirtyFields);
        if (
            temp.includes('column_name') ||
            temp.includes('table_name') ||
            temp.includes('dataSourceValue') ||
            temp.includes('metricValue') ||
            temp.includes('date_field') ||
            temp.includes('frequency') ||
            temp.includes('custom_query_availability')
        ) {
            console.log("temp================", temp);
            setModalShow(true);
        } else {
            handleOnSubmit(
                data,

                id,
                checkType,
                setCustomErrors,
                navigate,
                attribute,
                dirtyFields,
                authState,
            );
        }
    };

    useEffect(() => {
        const fetchSourceListByTableName = async () => {
            try {
                setIsLoading(true);
                const sourceList = await api.crud.get({
                    resource: 'api/masterConfiguration',
                    resourceId: id,
                });
                setList(sourceList);
                console.log("sourceList=======", sourceList);
                console.log("sourceList.datasourcevalue======>>>>", sourceList.data.data.res.datasourcevalue);
                sourceList?.data?.data?.result?.rows?.filter(
                    (i) => i.is_notify === true && i.is_active === true,
                )
                    .map((item) => {
                        notify.push({
                            user_id: item.notify_to,
                            display_name: item?.['notify.display_name'],
                        });
                    });
                sourceList?.data?.data?.result?.rows?.filter(
                    (i) =>
                        i.sharing_type === 'Group' && i.is_active === true,
                )
                    .map((item) => {
                        if (!option.includes(item.sharing_type))
                            option.push(item.sharing_type);

                        return groupselected.push({
                            group_id: item?.share_to_id,
                            group_name: item?.['group.group_name'],
                        });
                    });
                sourceList?.data?.data?.result?.rows?.filter(
                    (i) =>
                        i.sharing_type === 'Users' && i.is_active === true,
                )
                    .map((item) => {
                        if (!option.includes(item.sharing_type))
                            option.push(item.sharing_type);
                        return userselected.push({
                            user_id: item?.share_to_id,
                            display_name: item?.['user.display_name'],
                        });
                    });
                sourceList?.data?.data?.result?.rows?.filter((i) => i.sharing_type === 'Everyone')
                    .map((item) => {
                        if (!option.includes(item.sharing_type))
                            option.push(item.sharing_type);
                    });


                if (
                    sourceList.data.data.res.minimum_threshold >= 0 &&
                    sourceList.data.data.res.check_type === VOLUME
                ) {
                    setValue(
                        'minimum_threshold',
                        sourceList.data.data.res.minimum_threshold,
                    );
                    setLoadMinThreshold(true);
                }
                console.log("sourceList.data.data.res.metric_name", sourceList.data.data.res.metric_name);
                // const metricValue = sourceList.data.data.res.metric_name;
                // const tableName = sourceList.data.data.res.metric_name;
                // const datasourceValue = sourceList.data.data.res.datasourcevalue;
                // const mergedTableName = sourceList.data.data.res.custom_query_availability
                //     ? `${datasourceValue}:${metricValue}`
                //     : tableName;
                
                setValue('dataSourceValue', sourceList.data.data.res.datasourcevalue)
                setValue('column_name', sourceList.data.data.res.column_name);
                setValue('group_by_col', sourceList.data.data.res.group_by_col);
                setValue('date_field', sourceList.data.data.res.date_field);
                setValue('run_time', sourceList.data.data.res.run_time);
                setValue('frequency', sourceList.data.data.res.frequency);
                setValue('metricValue', sourceList.data.data.res.metric_name);
                setValue('custom_query', sourceList.data.data.res.custom_query);
                setValue('check_type', sourceList.data.data.res.check_type);
                setValue('custom_query_availability', sourceList.data.data.res.custom_query_availability);
                setFrequency(sourceList.data.data.res.frequency);
                setValue('latency', sourceList.data.data.res.latency);
                setValue('run_day', sourceList.data.data.res.run_day);
                setValue('notify_to', notify);
                setValue('selected_group', groupselected);
                setValue('selected_user', userselected);
                setValue('share_to', option);
                setValue('table_name', 
                // sourceList.data.data.res.custom_query_availability ? `${sourceList.data.data.res.datasourcevalue}:${sourceList.data.data.res.metric_name}`:
                sourceList.data.data.res.table_name);

                if (sourceList.data.data.res.frequency === 'Yearly') {
                    let d = getDateBasedOnDayVal(
                        sourceList.data.data.res.run_day,
                    );
                    let month = d.get('month');
                    let date = d.get('date');
                    // let newDate = moment();
                    // let day = newDate.day(date).format('dddd');
                    setValue('month', months[month]);
                    setMonth(months[month]);
                    setValue('date', date);
                }
                setValue(
                    'additional_threshold',
                    sourceList.data.data.res.additional_threshold,
                );
                console.log("sourceList.data.data.res.additional_threshold======>>>>", sourceList.data.data.res.additional_threshold,);
                setTableName(sourceList.data.data.res.table_name);
                setCheckType(sourceList.data.data.res.check_type);
                setIsLoading(false);
            } catch (error) { }
        };
        fetchSourceListByTableName();
    }, [id]); { }


    const runQuery = async () => {
        setIsButtonLoader(true);
        const formData = getValues();
        const { query, dataSourceValue } = formData;
        try {
            let res = await api.customquery.getQueryResponse(
                { resource: 'api/metrics/custom-query' },
                {
                    query: query,
                    dataSourceValue: dataSourceValue,
                    customQuery: true,
                },
            );
            if (res.statusText === 'OK' || res.status === 200) {
                setIsQueryValid(true);
                setQueryResult(res.data);
                let toGetDataColumns = [];
                console.log("res.data===>>>>", res.data);
                for (const key in res.data[0]) {
                    if (Object.prototype.hasOwnProperty.call(res.data[0], key)) {
                        const value = res.data[0][key];
                        if (typeof value === 'string' && !isNaN(Date.parse(value))) {
                            toGetDataColumns.push(key);
                        }
                    }
                }

                setDateColumsForCustomQuery(toGetDataColumns)

            } else {
                setIsQueryValid(false);
                setCustomQueryError(res.data.error);
            }

        } catch (error) {
            console.error('Error running SQL query:', error);
        } finally {
            setIsButtonLoader(false); // Set loading to false when query completes (success or error)
        }
    };


    return (<Container>
        <ConfirmationModal show={modalShow}
            onHide={
                () => setModalShow(false)
            }
            heading={'Warning'}
            body={
                'Current Metric Data will be deleted and reloaded as per the revised metrics'
            }
            success={'Add/Delete'}
            failure={'Cancel'}
            action={
                () => {
                    modalSubmit(tempdata);
                }
            } >
        </ConfirmationModal> {
            isLoading ? (<ContainerLoader> </ContainerLoader>
            ) : (<>
                <div className="d-flex justify-content-center align-items-center my-5" >
                    <div className="rounded-5 mx-2 my-4 p-4 w-75 position-relative"
                        style={
                            {
                                boxShadow: 'rgba(0,0,0,0.2) 0px 2px 8px 0px',
                                backgroundColor: 'whitesmoke',
                                marginLeft: '12.5%',
                            }
                        } > {
                            globalLoader && (< Spinner className="position-absolute opacity-100 top-50 start-50"
                                style={
                                    { zIndex: 1 }
                                } >
                            </Spinner>
                            )
                        } <h4 className={`fw-bold  ms-4 text-center  ${globalLoader ? `opacity-50` : null
                            }`}>
                            Update Metrics
                        </h4>
                        <div className="d-flex justify-content-end position-absolute"
                            style={{
                                right: '5%',
                                cursor: 'pointer',
                                position: 'static',
                            }}>
                            <Button
                                variant="dark"
                                disabled={globalLoader}
                                size="sm"
                                onClick={() => navigate('/')}>
                                <FaArrowLeft /> Back
                            </Button>
                        </div>


                        <Form
                            onSubmit={handleSubmit((data) => {
                                globalSubmit(data); // dateColums?.length, // validate date field , it will return true or false with integer representation
                                setTempdata(data);
                            })}
                            className={`  ${globalLoader ? `opacity-50` : null
                                }`}>

                            {list?.data?.data?.res?.custom_query_availability === false && (
                                <>
                                    <Row className="mb-4">
                                        <Col xs={4}>
                                            Check type -{' '}
                                            <span
                                                className="fw-bold text-success"
                                                style={{ fontSize: '27px' }}
                                            >
                                                {checkType}
                                            </span>
                                        </Col>
                                    </Row>

                                    <Row>

                                        <Controller
                                            control={control}
                                            name="table_name"
                                            rules={{
                                                required: true,
                                            }}
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <Dropdown
                                                    id="table_name"
                                                    label="Source"
                                                    busy={
                                                        sourceListData.length >
                                                            0
                                                            ? false
                                                            : true
                                                    }
                                                    filter="contains"
                                                    value={value}
                                                    data={sourceListData}
                                                    dataKey="table_name"
                                                    textField="table_name"
                                                    defaultValue={value}
                                                    onChange={(value) => {
                                                        onChange(
                                                            value?.table_name,
                                                        );
                                                        setTableName(
                                                            value?.table_name,
                                                        );
                                                        handleClearTableNameRelated();
                                                    }}
                                                    error={
                                                        !errors.table_name
                                                            ? ''
                                                            : errors.table_name
                                                                ?.type ===
                                                                'required'
                                                                ? 'Table Name is required'
                                                                : 'Invalid Table Name'
                                                    }
                                                />
                                            )}
                                        />
                                    </Row>

                                    <Row>
                                        <Controller
                                            control={control}
                                            name="metricValue"
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    label="Metric Name"
                                                    type="text"
                                                />
                                            )}
                                        />
                                    </Row>

                                    <Row>
                                        {[VOLUME, DISTRIBUTION].includes(
                                            checkType,
                                        ) && (
                                                <Col sm={12} md={6}>
                                                    <Controller
                                                        control={control}
                                                        name="column_name"
                                                        render={({
                                                            field: { onChange, value },
                                                        }) => (
                                                            <Dropdown
                                                                label="Attribute"
                                                                busy={
                                                                    sourceListByTableName?.length >
                                                                        0
                                                                        ? false
                                                                        : 'No data matches'
                                                                }
                                                                // disabled={
                                                                //     !sourceListByTableName.length ||
                                                                //     !checkType
                                                                // }
                                                                filter="contains"
                                                                value={value}
                                                                defaultValue={value}
                                                                data={attributeData()}
                                                                dataKey="column_name"
                                                                textField="column_name"
                                                                onChange={(value) => {
                                                                    onChange(
                                                                        value?.column_name,
                                                                    );
                                                                    setAttribute(value);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Col>
                                            )}
                                    </Row>


                                    <Row>
                                        {[FRESHNESS, VOLUME, DISTRIBUTION].includes(
                                            checkType,
                                        ) && (
                                                <Col sm={12} md={6}>
                                                    <Controller
                                                        control={control}
                                                        name="date_field"
                                                        render={({
                                                            field: { onChange, value },
                                                        }) => (
                                                            <Dropdown
                                                                label="Date Field"
                                                                // disabled={
                                                                //     !dateColums.length ||
                                                                //     !checkType
                                                                // }
                                                                filter="contains"
                                                                value={value}
                                                                defaultValue={value}
                                                                data={dateColums}
                                                                dataKey="column_name"
                                                                textField="column_name"
                                                                onChange={(value) => {
                                                                    onChange(
                                                                        value?.column_name,
                                                                    );
                                                                    setAttribute(value);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Col>
                                            )}
                                        {[VOLUME].includes(checkType) && (
                                            <Col sm={12} md={6}>
                                                <Controller
                                                    control={control}
                                                    name="group_by_col"
                                                    render={({
                                                        field: { onChange, value },
                                                    }) => (
                                                        <Dropdown
                                                            label="Group By"
                                                            // disabled={
                                                            //     !sourceListByTableName.length ||
                                                            //     !checkType ||
                                                            //     !attribute
                                                            // }
                                                            filter="contains"
                                                            value={value}
                                                            defaultValue={value}
                                                            data={sourceListByTableName?.filter(
                                                                ({
                                                                    column_name,
                                                                    data_type,
                                                                }) =>
                                                                    !attribute?.column_name?.startsWith(
                                                                        column_name,
                                                                    ) &&
                                                                    stringTypes.includes(
                                                                        data_type,
                                                                    ),
                                                            )}
                                                            dataKey="column_name"
                                                            textField="column_name"
                                                            onChange={(value) => {
                                                                onChange(
                                                                    value?.column_name,
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Col>
                                        )}
                                        {[FRESHNESS].includes(checkType) && (
                                            <Col sm={12} md={6}>
                                                <Controller
                                                    control={control}
                                                    name="frequency"
                                                    // rules={{ required: true }}
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                            name,
                                                        },
                                                    }) => (
                                                        <Dropdown
                                                            label="Frequency"
                                                            data={[
                                                                'Daily',
                                                                'Weekly',
                                                                'Monthly',
                                                                'Yearly',
                                                            ]}
                                                            value={value}
                                                            filter="contains"
                                                            // isMandatory={true}
                                                            defaultValue={value}
                                                            dataKey="table_name"
                                                            textField="table_name"
                                                            onChange={(value) => {
                                                                onChange(value);
                                                                setFrequency(value);
                                                                handleClearFrequencyRelated();
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Col>
                                        )}

                                        {[FRESHNESS].includes(checkType) && (
                                            <Col sm={12} md={6}>
                                                <Controller
                                                    control={control}
                                                    name="latency"
                                                    // rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Input
                                                            {...field}
                                                            label="Latency(in Days)"
                                                            defaultValue={
                                                                list.data.data
                                                                    .latency
                                                            }
                                                            type="number"
                                                        // isMandatory={true}
                                                        />
                                                    )}
                                                />
                                            </Col>
                                        )}

                                        {[FRESHNESS].includes(checkType) &&
                                            (frequency === 'Weekly' ||
                                                frequency === 'Daily' ||
                                                !frequency) && (
                                                <Col sm={12} md={6}>
                                                    <Controller
                                                        control={control}
                                                        name="run_day"
                                                        // rules={{ required: true }}
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                value,
                                                            },
                                                        }) => {
                                                            return (
                                                                <Dropdown
                                                                    label="Run day"
                                                                    filter="contains"
                                                                    value={value}
                                                                    data={days}
                                                                    dataKey="name"
                                                                    textField="name"
                                                                    disabled={
                                                                        !frequency ||
                                                                        frequency ===
                                                                        'Daily'
                                                                    }
                                                                    onChange={(
                                                                        value,
                                                                    ) => {
                                                                        onChange(
                                                                            getRundayVal(
                                                                                value,
                                                                            ),
                                                                        );
                                                                    }}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                            )}

                                        {[FRESHNESS].includes(checkType) &&
                                            frequency === 'Monthly' && (
                                                <Col sm={12} md={6}>
                                                    <Controller
                                                        control={control}
                                                        name="run_day"
                                                        // rules={{ required: true }}
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                value,
                                                            },
                                                        }) => {
                                                            return (
                                                                <Dropdown
                                                                    label="Run day"
                                                                    filter="contains"
                                                                    value={value}
                                                                    data={Array.from(
                                                                        {
                                                                            length: 31,
                                                                        },
                                                                    ).map(
                                                                        (o, i) =>
                                                                            i + 1,
                                                                    )}
                                                                    onChange={(
                                                                        value,
                                                                    ) => {
                                                                        onChange(
                                                                            getRundayVal(
                                                                                value,
                                                                            ),
                                                                        );
                                                                    }}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                            )}

                                        {[FRESHNESS].includes(checkType) &&
                                            frequency === 'Yearly' && (
                                                <Col sm={12} md={6}>
                                                    <Row>
                                                        <Col>
                                                            <Controller
                                                                control={control}
                                                                name="month"
                                                                // rules={{ required: true }}
                                                                render={({
                                                                    field: {
                                                                        onChange,
                                                                        value,
                                                                    },
                                                                }) => {
                                                                    return (
                                                                        <Dropdown
                                                                            label="Run day"
                                                                            filter="contains"
                                                                            defaultValue="January"
                                                                            value={
                                                                                value
                                                                            }
                                                                            data={
                                                                                months
                                                                            }
                                                                            onChange={(
                                                                                value,
                                                                            ) => {
                                                                                setMonth(
                                                                                    value,
                                                                                );
                                                                                onChange(
                                                                                    value,
                                                                                );
                                                                            }}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Controller
                                                                control={control}
                                                                name="date"
                                                                // rules={{ required: true }}
                                                                render={({
                                                                    field: {
                                                                        onChange,
                                                                        value,
                                                                    },
                                                                }) => {
                                                                    return (
                                                                        <Dropdown
                                                                            label=""
                                                                            className="mt-2"
                                                                            disabled={
                                                                                !month
                                                                            }
                                                                            filter="contains"
                                                                            value={
                                                                                value
                                                                            }
                                                                            data={Array.from(
                                                                                {
                                                                                    length: dateRange[
                                                                                        month
                                                                                    ]
                                                                                        ?.range,
                                                                                },
                                                                            )?.map(
                                                                                (
                                                                                    o,
                                                                                    i,
                                                                                ) =>
                                                                                    i +
                                                                                    1,
                                                                            )}
                                                                            onChange={(
                                                                                value,
                                                                            ) => {
                                                                                onChange(
                                                                                    value,
                                                                                );
                                                                            }}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )}

                                        {[FRESHNESS].includes(checkType) && (
                                            <Col sm={12} md={6}>
                                                <Controller
                                                    control={control}
                                                    name="run_time"
                                                    // rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Input
                                                            {...field}
                                                            type="time"
                                                            defaultValue={
                                                                list.data.data
                                                                    .run_time
                                                            }
                                                            label="Run Time"

                                                        // isMandatory={true}
                                                        />
                                                    )}
                                                />
                                            </Col>
                                        )}
                                        {[VOLUME, DISTRIBUTION].includes(
                                            checkType,
                                        ) && (
                                                <Col sm={12} md={6}>
                                                    <Controller
                                                        control={control}
                                                        name="column_name"
                                                        render={({
                                                            field: { onChange, value },
                                                        }) => (
                                                            <Controller
                                                                control={control}
                                                                name="additional_threshold"
                                                                // rules={{ required: true }}
                                                                render={({ field }) => {
                                                                    return (
                                                                        <Input
                                                                            {...field}
                                                                            type="number"
                                                                            label="Additional Threshold(In Percentage)"
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Col>
                                            )}
                                        {/* {[VOLUME].includes(checkType) &&
                                        loadMinThreshold && (
                                            <Col sm={12} md={6}>
                                                <Controller
                                                    control={control}
                                                    name="minimum_threshold"
                                                    // rules={{ required: true }}
                                                    render={({
                                                        field,
                                                        field: { value },
                                                    }) => {
                                                        return (
                                                            <Input
                                                                {...field}
                                                                value={value}
                                                                type="number"
                                                                label="Minimum Threshold"
                                                            />
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        )} */}
                                    </Row>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="fw-bold mb-2">
                                                Notify
                                            </div>
                                            <div className="">
                                                <Controller
                                                    control={control}
                                                    name="notify_to"
                                                    render={({
                                                        field: { onChange, value },
                                                    }) => (
                                                        <div className="custom-dropdown ">
                                                            <Multiselect
                                                                data={user}
                                                                dataKey="user_id"
                                                                textField="display_name"
                                                                defaultValue={value}
                                                                onChange={(
                                                                    value,
                                                                ) => {
                                                                    onChange(
                                                                        value?.map(
                                                                            (
                                                                                item,
                                                                            ) => {
                                                                                return item;
                                                                            },
                                                                        ),
                                                                    );
                                                                    setNotify(
                                                                        value?.map(
                                                                            (
                                                                                item,
                                                                            ) => {
                                                                                return item;
                                                                            },
                                                                        ),
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                ></Controller>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="fw-bold mb-2">
                                                Share
                                            </div>
                                            <div className="">
                                                <Controller
                                                    control={control}
                                                    name="share_to"
                                                    render={({
                                                        field: { onChange, value },
                                                    }) => (
                                                        <>
                                                            <Multiselect
                                                                data={share_to}
                                                                //textField={share_to}
                                                                //value={value}
                                                                defaultValue={value}
                                                                onChange={(
                                                                    value,
                                                                ) => {
                                                                    onChange(value);

                                                                    setOption(
                                                                        value,
                                                                    );
                                                                }}
                                                            ></Multiselect>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {option.includes('Group') ? (
                                            <div className="col-6">
                                                <div className="fw-bold mb-2">
                                                    Choose Groups
                                                </div>
                                                <div className="">
                                                    <Controller
                                                        control={control}
                                                        name="selected_group"
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                value,
                                                            },
                                                        }) => (
                                                            <>
                                                                <Multiselect
                                                                    data={
                                                                        groupData
                                                                            ?.data
                                                                            ?.data
                                                                            ?.rows
                                                                    }
                                                                    disabled={
                                                                        everyone
                                                                    }
                                                                    dataKey="group_id"
                                                                    textField="group_name"
                                                                    defaultValue={
                                                                        value
                                                                    }
                                                                    onChange={(
                                                                        value,
                                                                    ) => {
                                                                        onChange(
                                                                            value?.map(
                                                                                (
                                                                                    item,
                                                                                ) => {
                                                                                    return item;
                                                                                },
                                                                            ),
                                                                        );
                                                                        setGroupselected(
                                                                            value?.map(
                                                                                (
                                                                                    item,
                                                                                ) => {
                                                                                    return item;
                                                                                },
                                                                            ),
                                                                        );
                                                                    }}
                                                                ></Multiselect>
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        ) : null}
                                        {option.includes('Users') && (
                                            <div className="col-6">
                                                <div className="fw-bold mb-2">
                                                    Choose users
                                                </div>
                                                <div className="">
                                                    <Controller
                                                        control={control}
                                                        name="selected_user"
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                value,
                                                            },
                                                        }) => (
                                                            <>
                                                                <Multiselect
                                                                    data={user}
                                                                    disabled={
                                                                        everyone
                                                                    }
                                                                    dataKey="user_id"
                                                                    textField="display_name"
                                                                    defaultValue={
                                                                        value
                                                                    }
                                                                    onChange={(
                                                                        value,
                                                                    ) => {
                                                                        onChange(
                                                                            value?.map(
                                                                                (
                                                                                    item,
                                                                                ) => {
                                                                                    return item;
                                                                                },
                                                                            ),
                                                                        );
                                                                        setuserselected(
                                                                            value?.map(
                                                                                (
                                                                                    item,
                                                                                ) => {
                                                                                    return item;
                                                                                },
                                                                            ),
                                                                        );
                                                                    }}
                                                                ></Multiselect>
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <Row>
                                        <Col>
                                            {Object.values(customErrors).map(
                                                (error) => (
                                                    <ul>
                                                        <li className="text-danger">
                                                            {error}
                                                        </li>
                                                    </ul>
                                                ),
                                            )}
                                        </Col>
                                        <Col>
                                            <div className="d-flex justify-content-end">
                                                <Button
                                                    type="button"
                                                    className="btn btn-secondary  mt-4 me-4"
                                                    onClick={handleClear}
                                                >
                                                    Clear
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    className="btn btn-success mt-4 me-4"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? (
                                                        <Spinner
                                                            animation="border"
                                                            role="status"
                                                            style={{
                                                                height: '15px',
                                                                width: '15px',
                                                            }}
                                                        />
                                                    ) : (
                                                        'Update'
                                                    )}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )}

                        </Form>


                        <Form
                            onSubmit={handleSubmit((data) => {
                                globalSubmit(data); // dateColums?.length, // validate date field , it will return true or false with integer representation
                                setTempdata(data);
                            })}
                            className={`  ${globalLoader ? `opacity-50` : null
                                }`}>


                            {list?.data?.data?.res?.custom_query_availability === true && (
                                <>
                                    <Row className="mb-4">
                                        <Col xs={4}>
                                            Check type -{' '}
                                            <span
                                                className="fw-bold text-success"
                                                style={{ fontSize: '27px' }}
                                            >
                                                {checkType}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Controller
                                            control={control}
                                            name="dataSourceValue"
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropdown
                                                    id="dataSourceValue"
                                                    label="Data Source"
                                                    busy={datasourceListData.length > 0 ? false : true}
                                                    filter="contains"
                                                    value={value}
                                                    data={datasourceListData}
                                                    defaultValue={value}
                                                    dataKey="dataSourceValue"
                                                    textField="dataSourceValue"
                                                    onChange={(newValue) => {
                                                        onChange(newValue);
                                                        setDataSourceValue(newValue);
                                                    }}
                                                    error={
                                                        !errors.datasourcevalue
                                                            ? ''
                                                            : errors.datasourcevalue?.type === 'required'
                                                                ? 'Data Source is required'
                                                                : 'Invalid Data Source'
                                                    }
                                                />
                                            )}
                                        />


                                    </Row>
                                    <Row>
                                        <Controller
                                            control={control}
                                            name="query"
                                            defaultValue={list?.data?.data?.res?.custom_query || ''}
                                            render={({ field }) => (
                                                <AceEditor
                                                    mode="sql"
                                                    theme="textmate"
                                                    name="editor:query"
                                                    fontSize={16}
                                                    minLines={5}
                                                    maxLines={5}
                                                    width="100%"
                                                    showPrintMargin={false}
                                                    showGutter
                                                    placeholder="Write your Query here..."
                                                    editorProps={{
                                                        $blockScrolling: true,
                                                    }}
                                                    setOptions={{
                                                        enableBasicAutocompletion: true,
                                                        enableLiveAutocompletion: true,
                                                        enableSnippets: true,
                                                    }}
                                                    showLineNumbers
                                                    value={field.value}
                                                    onChange={(newQuery) => {
                                                        setQuery(newQuery);
                                                        field.onChange(newQuery);
                                                    }}
                                                />
                                            )}
                                        />
                                        <div className="d-flex justify-content-end">
                                            <Button
                                                type="button"
                                                className="btn  btn-secondary mt-4 me-4"
                                                onClick={HandleQueryClear}
                                            >
                                                Clear
                                            </Button>
                                            <Button
                                                onClick={runQuery}
                                                className="btn  btn-primary mt-4 "
                                                disabled={isButtonLoader}
                                            >
                                                {isButtonLoader ? (
                                                    <Spinner
                                                        animation="border"
                                                        role="status"
                                                        style={{
                                                            height: '15px',
                                                            width: '15px',
                                                        }}
                                                    />
                                                ) : 'Run Query'}
                                            </Button>
                                        </div>
                                    </Row>
                                    <Row>
                                        {queryResult ? (
                                            <>
                                                <CustomQueryResultTable
                                                    data={queryResult}
                                                />
                                            </>
                                        ) : (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'left',
                                                    alignItems: 'center',
                                                    color: 'red'
                                                }}
                                            >
                                                <h6>{customQueryError}</h6>
                                            </div>
                                        )}
                                    </Row>
                                    {isQueryValid ? <div>
                                        <Row>
                                            <Controller
                                                control={control}
                                                name="metricValue"
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        label="Metric Name"
                                                        type="text"
                                                    />
                                                )}
                                            />
                                        </Row>
                                        <Row>
                                            {[VOLUME, DISTRIBUTION].includes(
                                                checkType,
                                            ) && (
                                                    <Col sm={12} md={6}>
                                                        <Controller
                                                            control={control}
                                                            name="column_name"
                                                            render={({
                                                                field: { onChange, value },
                                                            }) => (
                                                                <Dropdown
                                                                    label="Attribute"
                                                                    busy={
                                                                        sourceListByTableName?.length >
                                                                            0
                                                                            ? false
                                                                            : 'No data matches'
                                                                    }
                                                                    // disabled={
                                                                    //     !sourceListByTableName.length ||
                                                                    //     !checkType
                                                                    // }
                                                                    filter="contains"
                                                                    value={value}
                                                                    defaultValue={value}
                                                                    data={attributeData()}
                                                                    dataKey="column_name"
                                                                    textField="column_name"
                                                                    onChange={(value) => {
                                                                        onChange(
                                                                            value?.column_name,
                                                                        );
                                                                        setAttribute(value);
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Col>
                                                )}
                                        </Row>


                                        <Row>
                                            {[FRESHNESS, VOLUME, DISTRIBUTION].includes(
                                                checkType,
                                            ) && (
                                                    <Col sm={12} md={6}>
                                                        <Controller
                                                            control={control}
                                                            name="date_field"
                                                            render={({
                                                                field: { onChange, value },
                                                            }) => (
                                                                <Dropdown
                                                                    label="Date Field"
                                                                    // disabled={
                                                                    //     !dateColums.length ||
                                                                    //     !checkType
                                                                    // }
                                                                    filter="contains"
                                                                    value={value}
                                                                    defaultValue={value}
                                                                    data={dateColums}
                                                                    dataKey="column_name"
                                                                    textField="column_name"
                                                                    onChange={(value) => {
                                                                        onChange(
                                                                            value?.column_name,
                                                                        );
                                                                        setAttribute(value);
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Col>
                                                )}
                                            {[VOLUME].includes(checkType) && (
                                                <Col sm={12} md={6}>
                                                    <Controller
                                                        control={control}
                                                        name="group_by_col"
                                                        render={({
                                                            field: { onChange, value },
                                                        }) => (
                                                            <Dropdown
                                                                label="Group By"
                                                                // disabled={
                                                                //     !sourceListByTableName.length ||
                                                                //     !checkType ||
                                                                //     !attribute
                                                                // }
                                                                filter="contains"
                                                                value={value}
                                                                defaultValue={value}
                                                                data={sourceListByTableName?.filter(
                                                                    ({
                                                                        column_name,
                                                                        data_type,
                                                                    }) =>
                                                                        !attribute?.column_name?.startsWith(
                                                                            column_name,
                                                                        ) &&
                                                                        stringTypes.includes(
                                                                            data_type,
                                                                        ),
                                                                )}
                                                                dataKey="column_name"
                                                                textField="column_name"
                                                                onChange={(value) => {
                                                                    onChange(
                                                                        value?.column_name,
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Col>
                                            )}
                                            {[FRESHNESS].includes(checkType) && (
                                                <Col sm={12} md={6}>
                                                    <Controller
                                                        control={control}
                                                        name="frequency"
                                                        // rules={{ required: true }}
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                value,
                                                                name,
                                                            },
                                                        }) => (
                                                            <Dropdown
                                                                label="Frequency"
                                                                data={[
                                                                    'Daily',
                                                                    'Weekly',
                                                                    'Monthly',
                                                                    'Yearly',
                                                                ]}
                                                                value={value}
                                                                filter="contains"
                                                                // isMandatory={true}
                                                                defaultValue={value}
                                                                dataKey="table_name"
                                                                textField="table_name"
                                                                onChange={(value) => {
                                                                    onChange(value);
                                                                    setFrequency(value);
                                                                    handleClearFrequencyRelated();
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Col>
                                            )}

                                            {[FRESHNESS].includes(checkType) && (
                                                <Col sm={12} md={6}>
                                                    <Controller
                                                        control={control}
                                                        name="latency"
                                                        // rules={{ required: true }}
                                                        render={({ field }) => (
                                                            <Input
                                                                {...field}
                                                                label="Latency(in Days)"
                                                                defaultValue={
                                                                    list.data.data
                                                                        .latency
                                                                }
                                                                type="number"
                                                            // isMandatory={true}
                                                            />
                                                        )}
                                                    />
                                                </Col>
                                            )}

                                            {[FRESHNESS].includes(checkType) &&
                                                (frequency === 'Weekly' ||
                                                    frequency === 'Daily' ||
                                                    !frequency) && (
                                                    <Col sm={12} md={6}>
                                                        <Controller
                                                            control={control}
                                                            name="run_day"
                                                            // rules={{ required: true }}
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    value,
                                                                },
                                                            }) => {
                                                                return (
                                                                    <Dropdown
                                                                        label="Run day"
                                                                        filter="contains"
                                                                        value={value}
                                                                        data={days}
                                                                        dataKey="name"
                                                                        textField="name"
                                                                        disabled={
                                                                            !frequency ||
                                                                            frequency ===
                                                                            'Daily'
                                                                        }
                                                                        onChange={(
                                                                            value,
                                                                        ) => {
                                                                            onChange(
                                                                                getRundayVal(
                                                                                    value,
                                                                                ),
                                                                            );
                                                                        }}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                    </Col>
                                                )}

                                            {[FRESHNESS].includes(checkType) &&
                                                frequency === 'Monthly' && (
                                                    <Col sm={12} md={6}>
                                                        <Controller
                                                            control={control}
                                                            name="run_day"
                                                            // rules={{ required: true }}
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    value,
                                                                },
                                                            }) => {
                                                                return (
                                                                    <Dropdown
                                                                        label="Run day"
                                                                        filter="contains"
                                                                        value={value}
                                                                        data={Array.from(
                                                                            {
                                                                                length: 31,
                                                                            },
                                                                        ).map(
                                                                            (o, i) =>
                                                                                i + 1,
                                                                        )}
                                                                        onChange={(
                                                                            value,
                                                                        ) => {
                                                                            onChange(
                                                                                getRundayVal(
                                                                                    value,
                                                                                ),
                                                                            );
                                                                        }}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                    </Col>
                                                )}

                                            {[FRESHNESS].includes(checkType) &&
                                                frequency === 'Yearly' && (
                                                    <Col sm={12} md={6}>
                                                        <Row>
                                                            <Col>
                                                                <Controller
                                                                    control={control}
                                                                    name="month"
                                                                    // rules={{ required: true }}
                                                                    render={({
                                                                        field: {
                                                                            onChange,
                                                                            value,
                                                                        },
                                                                    }) => {
                                                                        return (
                                                                            <Dropdown
                                                                                label="Run day"
                                                                                filter="contains"
                                                                                defaultValue="January"
                                                                                value={
                                                                                    value
                                                                                }
                                                                                data={
                                                                                    months
                                                                                }
                                                                                onChange={(
                                                                                    value,
                                                                                ) => {
                                                                                    setMonth(
                                                                                        value,
                                                                                    );
                                                                                    onChange(
                                                                                        value,
                                                                                    );
                                                                                }}
                                                                            />
                                                                        );
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Controller
                                                                    control={control}
                                                                    name="date"
                                                                    // rules={{ required: true }}
                                                                    render={({
                                                                        field: {
                                                                            onChange,
                                                                            value,
                                                                        },
                                                                    }) => {
                                                                        return (
                                                                            <Dropdown
                                                                                label=""
                                                                                className="mt-2"
                                                                                disabled={
                                                                                    !month
                                                                                }
                                                                                filter="contains"
                                                                                value={
                                                                                    value
                                                                                }
                                                                                data={Array.from(
                                                                                    {
                                                                                        length: dateRange[
                                                                                            month
                                                                                        ]
                                                                                            ?.range,
                                                                                    },
                                                                                )?.map(
                                                                                    (
                                                                                        o,
                                                                                        i,
                                                                                    ) =>
                                                                                        i +
                                                                                        1,
                                                                                )}
                                                                                onChange={(
                                                                                    value,
                                                                                ) => {
                                                                                    onChange(
                                                                                        value,
                                                                                    );
                                                                                }}
                                                                            />
                                                                        );
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                )}

                                            {[FRESHNESS].includes(checkType) && (
                                                <Col sm={12} md={6}>
                                                    <Controller
                                                        control={control}
                                                        name="run_time"
                                                        // rules={{ required: true }}
                                                        render={({ field }) => (
                                                            <Input
                                                                {...field}
                                                                type="time"
                                                                defaultValue={
                                                                    list.data.data
                                                                        .run_time
                                                                }
                                                                label="Run Time"

                                                            // isMandatory={true}
                                                            />
                                                        )}
                                                    />
                                                </Col>
                                            )}
                                            {[VOLUME, DISTRIBUTION].includes(
                                                checkType,
                                            ) && (
                                                    <Col sm={12} md={6}>
                                                        <Controller
                                                            control={control}
                                                            name="column_name"
                                                            render={({
                                                                field: { onChange, value },
                                                            }) => (
                                                                <Controller
                                                                    control={control}
                                                                    name="additional_threshold"
                                                                    // rules={{ required: true }}
                                                                    render={({ field }) => {
                                                                        return (
                                                                            <Input
                                                                                {...field}
                                                                                type="number"
                                                                                label="Additional Threshold(In Percentage)"
                                                                            />
                                                                        );
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Col>
                                                )}
                                            {/* {[VOLUME].includes(checkType) &&
                                        loadMinThreshold && (
                                            <Col sm={12} md={6}>
                                                <Controller
                                                    control={control}
                                                    name="minimum_threshold"
                                                    // rules={{ required: true }}
                                                    render={({
                                                        field,
                                                        field: { value },
                                                    }) => {
                                                        return (
                                                            <Input
                                                                {...field}
                                                                value={value}
                                                                type="number"
                                                                label="Minimum Threshold"
                                                            />
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        )} */}
                                        </Row>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="fw-bold mb-2">
                                                    Notify
                                                </div>
                                                <div className="">
                                                    <Controller
                                                        control={control}
                                                        name="notify_to"
                                                        render={({
                                                            field: { onChange, value },
                                                        }) => (
                                                            <div className="custom-dropdown ">
                                                                <Multiselect
                                                                    data={user}
                                                                    dataKey="user_id"
                                                                    textField="display_name"
                                                                    defaultValue={value}
                                                                    onChange={(
                                                                        value,
                                                                    ) => {
                                                                        onChange(
                                                                            value?.map(
                                                                                (
                                                                                    item,
                                                                                ) => {
                                                                                    return item;
                                                                                },
                                                                            ),
                                                                        );
                                                                        setNotify(
                                                                            value?.map(
                                                                                (
                                                                                    item,
                                                                                ) => {
                                                                                    return item;
                                                                                },
                                                                            ),
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    ></Controller>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="fw-bold mb-2">
                                                    Share
                                                </div>
                                                <div className="">
                                                    <Controller
                                                        control={control}
                                                        name="share_to"
                                                        render={({
                                                            field: { onChange, value },
                                                        }) => (
                                                            <>
                                                                <Multiselect
                                                                    data={share_to}
                                                                    //textField={share_to}
                                                                    //value={value}
                                                                    defaultValue={value}
                                                                    onChange={(
                                                                        value,
                                                                    ) => {
                                                                        onChange(value);

                                                                        setOption(
                                                                            value,
                                                                        );
                                                                    }}
                                                                ></Multiselect>
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {option.includes('Group') ? (
                                                <div className="col-6">
                                                    <div className="fw-bold mb-2">
                                                        Choose Groups
                                                    </div>
                                                    <div className="">
                                                        <Controller
                                                            control={control}
                                                            name="selected_group"
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    value,
                                                                },
                                                            }) => (
                                                                <>
                                                                    <Multiselect
                                                                        data={
                                                                            groupData
                                                                                ?.data
                                                                                ?.data
                                                                                ?.rows
                                                                        }
                                                                        disabled={
                                                                            everyone
                                                                        }
                                                                        dataKey="group_id"
                                                                        textField="group_name"
                                                                        defaultValue={
                                                                            value
                                                                        }
                                                                        onChange={(
                                                                            value,
                                                                        ) => {
                                                                            onChange(
                                                                                value?.map(
                                                                                    (
                                                                                        item,
                                                                                    ) => {
                                                                                        return item;
                                                                                    },
                                                                                ),
                                                                            );
                                                                            setGroupselected(
                                                                                value?.map(
                                                                                    (
                                                                                        item,
                                                                                    ) => {
                                                                                        return item;
                                                                                    },
                                                                                ),
                                                                            );
                                                                        }}
                                                                    ></Multiselect>
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            ) : null}
                                            {option.includes('Users') && (
                                                <div className="col-6">
                                                    <div className="fw-bold mb-2">
                                                        Choose users
                                                    </div>
                                                    <div className="">
                                                        <Controller
                                                            control={control}
                                                            name="selected_user"
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    value,
                                                                },
                                                            }) => (
                                                                <>
                                                                    <Multiselect
                                                                        data={user}
                                                                        disabled={
                                                                            everyone
                                                                        }
                                                                        dataKey="user_id"
                                                                        textField="display_name"
                                                                        defaultValue={
                                                                            value
                                                                        }
                                                                        onChange={(
                                                                            value,
                                                                        ) => {
                                                                            onChange(
                                                                                value?.map(
                                                                                    (
                                                                                        item,
                                                                                    ) => {
                                                                                        return item;
                                                                                    },
                                                                                ),
                                                                            );
                                                                            setuserselected(
                                                                                value?.map(
                                                                                    (
                                                                                        item,
                                                                                    ) => {
                                                                                        return item;
                                                                                    },
                                                                                ),
                                                                            );
                                                                        }}
                                                                    ></Multiselect>
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <Row>
                                            <Col>
                                                {Object.values(customErrors).map(
                                                    (error) => (
                                                        <ul>
                                                            <li className="text-danger">
                                                                {error}
                                                            </li>
                                                        </ul>
                                                    ),
                                                )}
                                            </Col>
                                            <Col>
                                                <div className="d-flex justify-content-end">
                                                    <Button
                                                        type="button"
                                                        className="btn btn-secondary  mt-4 me-4"
                                                        onClick={handleClear}
                                                    >
                                                        Clear
                                                    </Button>
                                                    <Button
                                                        type="submit"
                                                        className="btn btn-success mt-4 me-4"
                                                        disabled={isSubmitting}
                                                    >
                                                        {isSubmitting ? (
                                                            <Spinner
                                                                animation="border"
                                                                role="status"
                                                                style={{
                                                                    height: '15px',
                                                                    width: '15px',
                                                                }}
                                                            />
                                                        ) : (
                                                            'Update'
                                                        )}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div> : <></>}
                                </>

                            )}

                        </Form>
                    </div>
                </div>
            </>
            )}
    </Container>
    );
}