// import * as http from './http';
import ApiService from './service';
import * as http from './http';

export class PipelineService extends ApiService {
    async metricsData(request, data) {
        const url = `${this.apiDomain}/${request.resource}`;
        return http.post(url, data, this.store);
    }
    async runBasedPipelineHistoryData(request, data) {
        const url = `${this.apiDomain}/${request.resource}`;
        return http.post(url, data, this.store);
    }

    async successFailureStackedBarChart(id) {
        const url = `${this.apiDomain}/api/PipelineDashboard/pipelineChartData?param=${id} `;
        const response = await http
            .get(url, this.store)
            .catch((err) => console.log(err));
        return response;
    }

    async pipelineForecastChartData(request, data) {
        const url = `${this.apiDomain}/${request.resource}`;
        return http.post(url, data, this.store);
    }
}
