

import React, { useState,useEffect } from 'react';
import { useTable } from 'react-table';
import InfiniteScroll from 'react-infinite-scroll-component';
//import './DynamicTable.css'; // Import CSS file for custom styling

const CustomQueryResultTable = ({ data }) => {
  // Set the initial state for pagination
  const [items, setItems] = useState(data.length);

  // Function to load more data on scroll
//   const loadMoreData = () => {
//     setItems((prevItems) => prevItems + 10);
//   };

  // Create columns based on keys
  // const columns = React.useMemo(
  //   () => (data.length > 0 ? Object.keys(data[0]).map(key => ({ Header: key, accessor: key })) : []),
  //   [data]
  // );
  const columns = React.useMemo(
    () =>
      data.length > 0
        ? Object.keys(data[0]).map(key => ({
            Header: key
              .split('_')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' '),
            accessor: key,
          }))
        : [],
    [data]
  );

  // Create table instance using react-table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data: data.slice(0, items)
  });

  return (
    <div className="table-container">
      <InfiniteScroll
        dataLength={items}
        // next={loadMoreData}
        hasMore={items < data.length}
        // loader={<h4>Loading...</h4>}
        // scrollThreshold={0.9}
        style={{ overflowY: 'scroll', maxHeight: '300px' }} // Adjust height as needed
      >
      <table {...getTableProps()}  hover
      className="table-responsive tableOverflow custom-table-height tablefontsize querytable"
      style={{
        // borderCollapse: "separate",
        borderSpacing: "0  15px",
        backgroundColor: "#fff",
        fontSize: "14px",
       
      }}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} className="border-bottom bg-white">
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()} className="py-2 px-2 fw-bold">{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}className='border-bottom'>
                  {row.cells.map(cell => {
                    return <td className='px-2' {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
};

export default CustomQueryResultTable;

