import ApiService from './service';
import * as http from './http';

export class CustomQueryService extends ApiService {

    async getQueryResponse(request, data) {
        const url = `${this.apiDomain}/${request.resource}`;
        console.log("url",url);
        let response = http.post(url, data, this.store);
        console.log("store",this.store);
        console.log(data,"data");
        return response;
    }

}