import React, { useEffect, useState } from 'react';
import api from '../../api';
import 'react-widgets/scss/styles.scss';
import { Form, Button, Container, Spinner } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { Controller, useForm } from 'react-hook-form';
import 'bootstrap/dist/css/bootstrap.min.css';
import validator from 'validator';
import { DropdownList } from 'react-widgets';
import { useNavigate, useParams } from 'react-router-dom';
import ContainerLoader from '../loader/container-loader';

export default function EditGroup({ setIsOpen, isOpen }) {
    const [loading, setLoading] = useState(true);
    const [isloadingbutton, setIsloadingbutton] = useState(false);

    const [group, setGroup] = useState(false);

    const navigate = useNavigate();
    const params = useParams();
    const status = [
        { Status: 'Active', IsActive: true },
        { Status: 'Inactive', IsActive: false },
    ];

    const {
        handleSubmit,
        control,

        formState: { errors },
        setValue,
        reset,
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: {
            group_name: '',
            description: '',
            is_active: '',
        },
    });

    useEffect(async () => {
        try {
            setLoading(true);
            const response = await api.grouplist.getGroupById(params.id);
            if (response.data.status == 200) {
                setGroup(true);
                setValue('group_name', response.data.data.group_name);
                setValue('description', response.data.data.description);
                setValue('is_active', response.data.data.is_active);
            }
            setLoading(false);
        } catch (error) {}
    }, []);

    const handleOnSumit = async (data) => {
        try {
            setIsloadingbutton(true);
            const request = { resource: `api/groups/${params.id}` };
            const res = await api.grouplist.updateGroup(request, {
                ...data,
            });
            if (res.data.status === 200) {
                toastr.success('Success', 'Group updated successfully');
                setIsloadingbutton(false);
                navigate('/groups');
            } else {
                toastr.error('Error', res.data.message);
            }
        } catch (error) {
            toastr.error('Error', error.message);
        }
    };

    return (
        <Container>
            {loading ? (
                <ContainerLoader></ContainerLoader>
            ) : (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '80vh' }}
                >
                    <div
                        className="rounded-5 mx-2 my-4 p-4 w-75 position-relative"
                        style={{
                            boxShadow: 'rgba(0,0,0,0.2) 0px 2px 8px 0px',
                            backgroundColor: 'whitesmoke',
                            marginLeft: '12.5%',
                        }}
                    >
                        <h4 className="fw-bold ms-4 text-center">Edit Group</h4>
                        <>
                            <>
                                {group ? (
                                    <>
                                        <Form
                                            onSubmit={handleSubmit(
                                                handleOnSumit,
                                            )}
                                        >
                                            <div className="col-12">
                                                <div className="container mt-4">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="font-weight-semibold mb-2">
                                                                Group Name
                                                            </div>

                                                            <Controller
                                                                control={
                                                                    control
                                                                }
                                                                name="group_name"
                                                                defaultValue={
                                                                    ''
                                                                }
                                                                render={({
                                                                    field: {
                                                                        value,
                                                                        name,
                                                                    },
                                                                }) => (
                                                                    <>
                                                                        <input
                                                                            value={
                                                                                value
                                                                            }
                                                                            name={
                                                                                name
                                                                            }
                                                                            className="form-control mt-1"
                                                                            onChange={(
                                                                                e,
                                                                            ) =>
                                                                                setValue(
                                                                                    'group_name',
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                                )
                                                                            }
                                                                            id="grid-group-name"
                                                                            type="text"
                                                                            aria-label="gname"
                                                                        />
                                                                    </>
                                                                )}
                                                            ></Controller>
                                                        </div>

                                                        <div className="col-6">
                                                            <div className="font-weight-semibold mb-2">
                                                                Description
                                                            </div>

                                                            <Controller
                                                                control={
                                                                    control
                                                                }
                                                                name="description"
                                                                defaultValue={
                                                                    ''
                                                                }
                                                                render={({
                                                                    field: {
                                                                        value,
                                                                        name,
                                                                    },
                                                                }) => (
                                                                    <>
                                                                        <input
                                                                            value={
                                                                                value
                                                                            }
                                                                            name={
                                                                                name
                                                                            }
                                                                            className="form-control mt-1"
                                                                            onChange={(
                                                                                e,
                                                                            ) =>
                                                                                setValue(
                                                                                    'description',
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                                )
                                                                            }
                                                                            id="grid-description"
                                                                            type="text"
                                                                            aria-label="description"
                                                                        />
                                                                    </>
                                                                )}
                                                            ></Controller>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="container mt-4">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="font-weight-semibold mb-2">
                                                                Status
                                                            </div>
                                                            <div className="relative">
                                                                <Controller
                                                                    control={
                                                                        control
                                                                    }
                                                                    name="is_active"
                                                                    defaultValue={
                                                                        ''
                                                                    }
                                                                    render={({
                                                                        field: {
                                                                            onChange,
                                                                            value,
                                                                        },
                                                                    }) => (
                                                                        <div className="custom-dropdown ">
                                                                            <DropdownList
                                                                                data={
                                                                                    status
                                                                                }
                                                                                value={
                                                                                    value
                                                                                }
                                                                                dataKey="IsActive"
                                                                                textField="Status"
                                                                                defaultValue={
                                                                                    value
                                                                                }
                                                                                onChange={(
                                                                                    data,
                                                                                ) => {
                                                                                    onChange(
                                                                                        data.IsActive,
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                ></Controller>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-end">
                                                    <Button
                                                        type="button"
                                                        className="btn btn my-3  mt-4 mx-3"
                                                        variant="outline-secondary"
                                                        onClick={() =>
                                                            navigate('/groups')
                                                        }
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        disabled={
                                                            isloadingbutton
                                                        }
                                                        type="submit"
                                                        className="btn btn my-3  mt-4 px-3"
                                                        style={{
                                                            backgroundColor:
                                                                'green',
                                                        }}
                                                        variant="success"
                                                    >
                                                        {isloadingbutton ? (
                                                            <div className="d-flex flex-row gap-2">
                                                                <Spinner
                                                                    className="text-dark  mx-2"
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        ) : (
                                                            <>{'Save'}</>
                                                        )}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                    </>
                                ) : null}
                            </>
                        </>
                    </div>
                </div>
            )}
        </Container>
    );
}
